import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import {
    ActivitySearchResponse,
    addExternalUserToEngagement,
    AddExternalUserToEngagementArgs,
    addExternalUserToProject,
    AddExternalUserToProjectArgs,
    addOrUpdateUserOnEngagement,
    addOrUpdateUserOnProject,
    AddUpdateUserOnEngagementArgs,
    AddUpdateUserOnProjectArgs,
    bulkUploadEngagementEvents,
    bulkUploadEngagementRequests,
    bulkUploadProjectEvents,
    bulkUploadProjectRequests,
    ClientSearchArgs,
    ClientSearchResponse,
    closeOrReopenEngagement,
    CloseOrReopenEngagementArgs,
    CloseOrReopenEngagementResponse,
    closeOrReopenProject,
    CloseOrReopenProjectArgs,
    CloseOrReopenProjectResponse,
    createCustomEngagement,
    createCustomProject,
    createEngagement,
    createEngagementFolders,
    createProject,
    createNonProvisionedEngagementProject,
    createProjectFolders,
    createRequestComment,
    CreateRequestCommentArgs,
    createRequestConversation,
    CreateRequestConversationArgs,
    deleteEngagementDocument,
    deleteEngagementEvent,
    DeleteEngagementEventArgs,
    deleteEngagementFolder,
    deleteEngagementRequest,
    DeleteEngagementRequestArgs,
    deleteProjectDocument,
    deleteProjectEvent,
    DeleteProjectEventArgs,
    deleteProjectFolder,
    deleteProjectRequest,
    DeleteProjectRequestArgs,
    deleteRequestComment,
    DeleteRequestCommentArgs,
    deleteRequestConversation,
    DeleteRequestConversationArgs,
    deleteUserFromEngagement,
    DeleteUserFromEngagementArgs,
    deleteUserFromProject,
    DeleteUserFromProjectArgs,
    Document,
    Engagement,
    EngagementActivitySearchArgs,
    EngagementEventSearchArgs,
    EngagementSearchArgs,
    EngagementSearchResponse,
    Event,
    FeatureFlagResponse,
    getClients,
    getCurrentUser,
    getEngagement,
    getEngagementActivityTypes,
    GetEngagementActivityTypesArgs,
    getEngagementBoxFolderLink,
    getEngagementDocumentPreviewLink,
    getEngagementDocuments,
    getEngagementEvent,
    getEngagementNotificationSettings,
    getEngagementRequest,
    getEngagementRequestsByEngagementId,
    getEngagementRollup,
    getEngagementsByClientId,
    getEngagementSummary,
    getEngagementTaxNotice,
    getEngagementTaxNotices,
    getEngagementTypes,
    getDashboardUsers,
    getEngagementUsers,
    getFeatureFlags,
    getMyRoleOnEngagement,
    getMyRoleOnProject,
    getNavItems,
    getNavItemsByEngagement,
    getNavItemsByProject,
    getNotificationOptions,
    getPermissionsByRole,
    getProject,
    getProjectActivityTypes,
    GetProjectActivityTypesArgs,
    getProjectBoxFolderLink,
    getProjectDocumentPreviewLink,
    getProjectDocuments,
    getProjectEvent,
    getProjectNotificationSettings,
    getProjectRequest,
    getProjectRequestsByProjectId,
    getProjectTaxNotice,
    getProjectTaxNotices,
    getProjectsByEngagementId,
    getProjectsByProjectCode,
    getProjectSummary,
    getProjectUsers,
    getRegionLookup,
    getRegions,
    getRequestConversations,
    getRequestStatuses,
    getRoles,
    getStates,
    matchRegion,
    NotificationSettings,
    PermissionsByRoleResponse,
    PostCustomEngagementArgs,
    PostCustomProjectArgs,
    PostEngagementArgs,
    postEngagementEvent,
    PostEngagementEventArgs,
    postEngagementRequest,
    PostEngagementRequestArgs,
    createEngagementTaxNotice,
    PostEngagementTaxNoticeArgs,
    postProjectEvent,
    PostProjectEventArgs,
    postProjectRequest,
    PostProjectRequestArgs,
    createProjectTaxNotice,
    PostProjectTaxNoticeArgs,
    Project,
    ProjectActivitySearchArgs,
    ProjectEventSearchArgs,
    ProjectSearchArgs,
    ProjectSearchResponse,
    PublishRequestCommentArgs,
    PublishRequestConversationArgs,
    putEngagementEvent,
    PutEngagementEventArgs,
    putEngagementRequest,
    PutEngagementRequestArgs,
    putProjectEvent,
    PutProjectEventArgs,
    putProjectRequest,
    PutProjectRequestArgs,
    renameEngagementFolder,
    renameProjectFolder,
    Request,
    requestAccessToEngagement,
    requestAccessToProject,
    RequestAccessToProjectArgs,
    searchClients,
    searchEngagementActivities,
    searchEngagementEvents,
    searchEngagements,
    searchProjectActivities,
    searchProjectEvents,
    searchProjects,
    searchUsers,
    SearchUsersArgs,
    togglePublishComment,
    togglePublishConversation,
    updateEngagement,
    UpdateEngagementNotificationSettingArgs,
    updateEngagementNotificationSettings,
    updateProject,
    UpdateProjectNotificationSettingArgs,
    updateProjectNotificationSettings,
    UploadDocumentsToEngagementRequestArgs,
    UploadDocumentsToProjectRequestArgs,
    UploadDocumentsToEngagementTaxNoticeArgs,
    UploadDocumentsToProjectTaxNoticeArgs,
    uploadDocumentToEngagementRequest,
    uploadDocumentToProjectRequest,
    uploadDocumentToEngagementTaxNotice,
    uploadDocumentToProjectTaxNotice,
    uploadEngagementDocument,
    uploadProjectDocument,
    User,
    getEngagementRequestsByEngagementIdForRegion,
    getEngagementSummaryForRegion,
    searchEngagementEventsForRegion,
    Report,
    removeReport,
    authReport,
    searchUserProject,
    getProjectTypes,
    SearchUserProjectsArgs,
    UserProjectSummaryResponse,
    getRegionStorageLookup,
    getRequestTypes,
    updatePhoneNumber,
    getProjectFolderStructure,
    getEngagementFolderStructure,
    getApplications,
    enableApplication,
    ApplicationArgs,
    Application,
    updateApplication,
    uploadDocumentToEngagementDeliverable,
    UploadDocumentsToEngagementDeliverableArgs,
    uploadDocumentToProjectDeliverable,
    UploadDocumentsToProjectDeliverableArgs,
    PutEngagementTaxNoticeArgs,
    updateEngagementTaxNotice,
    TaxNotice,
    PutProjectTaxNoticeArgs,
    updateProjectTaxNotice,
    deleteEngagementTaxNotice,
    DeleteProjectTaxNoticeArgs,
    deleteProjectTaxNotice,
    DeleteEngagementTaxNoticeArgs,
    Workspace,
    createProjectWorkspace,
    CreateProjectWorkspaceArgs,
    createEngagementWorkspace,
    CreateEngagementWorkspaceArgs,
    uploadEngagementReportFile,
    EngagementReportFileArgs,
    uploadProjectReportFile,
    ProjectReportFileArgs,
    getProjectWorkspace,
    getEngagementWorkspace,
    createProjectReport,
    createEngagementReport,
    UpdateProjectReportsArgs,
    UpdateEngagementReportsArgs,
    getEngagementReports,
    getProjectReports,
    updateEngagementReport,
    updateProjectReport,
} from "./api.ts";

import { errorMessage, ErrorResponse, FileContainer, handleError, mutationErrorHandler, useToast } from "am-tax-fe-core";
import { PLACEHOLDER_FOR_ID_NOT_CREATED } from "../constants/index.ts";
import { getEngagementProjectIdsStr, getProjectIdsNotInEngagementRegion } from "../util/projectUtils.ts";

export function useRegions(enabled = true) {
    return useQuery({
        ...queryKeys.regions.all,
        queryFn: getRegions,
        gcTime: Infinity,
        staleTime: Infinity,
        enabled,
    });
}

export function useRegionMatch(args: { clientId: string; engagementId?: string; projectId?: string }, enabled = true) {
    const query = useQuery({
        ...queryKeys.regions.lookup,
        queryFn: getRegionLookup,
        gcTime: Infinity,
        staleTime: Infinity,
        enabled,
    });

    return query.data && enabled ? matchRegion(query.data, args) : undefined;
}

export function useRegionStorageLookup() {
    const query = useQuery({
        ...queryKeys.regionStorages.lookup,
        queryFn: getRegionStorageLookup,
        gcTime: Infinity,
        staleTime: Infinity,
    });
    return query;
}

export function useClients() {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.clients.all,
        queryFn: async () => {
            const clients = await getClients();
            clients.forEach(client => {
                queryClient.setQueryData(queryKeys.clients.byId(client.clientId).queryKey, client);
            });
            return clients;
        },
    });
}

//Engagements
export function useClientEngagements(clientId: string | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.engagements.byClientId(clientId!),
        enabled: !!clientId && enabled,
        queryFn: () => getEngagementsByClientId(clientId!),
    });
}

async function invalidateCreateEngagementCache(queryClient: QueryClient, clientId: string) {
    await queryClient.invalidateQueries(queryKeys.clients.all);
    await queryClient.invalidateQueries({ queryKey: queryKeys.clients.search._def });
    await queryClient.invalidateQueries(queryKeys.engagements.byClientId(clientId));
    await queryClient.invalidateQueries(queryKeys.regions.lookup);
}

export function useCreateEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostEngagementArgs) => createEngagement(args),
        onSuccess: async (response: Engagement) => {
            await invalidateCreateEngagementCache(queryClient, response.clientId);
        },
        ...mutationErrorHandler(toast, "Unable to save engagement"),
    });
}

export function useCreateCustomEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostCustomEngagementArgs) => createCustomEngagement(args),
        onSuccess: async (response: Engagement) => {
            await invalidateCreateEngagementCache(queryClient, response.clientId);
        },
        ...mutationErrorHandler(toast, "Unable to save engagement"),
    });
}

export function useSaveEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateEngagement,
        onSuccess: async (response: Engagement) => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.projects._def });
            return queryClient.invalidateQueries(queryKeys.engagements.byEngagementId(response.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to save engagement"),
    });
}

export function useEngagement(engagementId: string | null | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.engagements.byEngagementId(engagementId!),
        enabled: enabled && !!engagementId,
        queryFn: () => getEngagement(engagementId!),
    });
}

export function useRequestAccessToEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: requestAccessToEngagement,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.engagements.byClientId._def });
            await queryClient.invalidateQueries({ queryKey: queryKeys.engagements.search._def });
            await queryClient.invalidateQueries({ queryKey: queryKeys.projects.search._def });
        },
        ...mutationErrorHandler(toast, "Unable to save project"),
    });
}

export function useEngagementTypes() {
    return useQuery({
        ...queryKeys.engagementTypes.all,
        queryFn: getEngagementTypes,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load engagement types"),
    });
}
export function useCloseOrReopenEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: closeOrReopenEngagement,
        onSuccess: async (response: CloseOrReopenEngagementResponse, args: CloseOrReopenEngagementArgs) => {
            await queryClient.invalidateQueries(queryKeys.engagements.byEngagementId(args.engagementId));
            await queryClient.invalidateQueries(queryKeys.roles.myRoleByEngagementId(args.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to close or reopen project"),
    });
}
//Projects
export function useProjects(engagementId: string | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.projects.byEngagementId(engagementId!),
        enabled: enabled && !!engagementId,
        queryFn: () => getProjectsByEngagementId(engagementId!),
    });
}

export function useProjectTypes() {
    return useQuery({
        ...queryKeys.projectTypes.all,
        queryFn: getProjectTypes,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load project types"),
    });
}

export function useAgressoProjects(projectCode: string | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.projects.byProjectCode(projectCode!),
        enabled: enabled && !!projectCode,
        queryFn: () => getProjectsByProjectCode(projectCode!),
    });
}

async function invalidateCreateProjectCache(queryClient: QueryClient, engagementId: string) {
    await queryClient.invalidateQueries(queryKeys.clients.all);
    await queryClient.invalidateQueries({ queryKey: queryKeys.projects._def });
    await queryClient.invalidateQueries(queryKeys.engagements.byEngagementId(engagementId));
    await queryClient.invalidateQueries(queryKeys.projects.byEngagementId(engagementId));
    await queryClient.invalidateQueries(queryKeys.regions.lookup);
}

export function useCreateProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createProject,
        onSuccess: async (response: Project) => {
            await invalidateCreateProjectCache(queryClient, response.engagementId);
        },
        ...mutationErrorHandler(toast, "Unable to save project"),
    });
}

export function useCreateNonProvisionedEngagementProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createNonProvisionedEngagementProject,
        onSuccess: async (response: Project) => {
            await invalidateCreateProjectCache(queryClient, response.engagementId);
        },
        ...mutationErrorHandler(toast, "Unable to save project"),
    });
}

export function useCreateCustomProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostCustomProjectArgs) => createCustomProject(args),
        onSuccess: async (response: Project) => {
            await invalidateCreateProjectCache(queryClient, response.engagementId);
        },
        ...mutationErrorHandler(toast, "Unable to save project"),
    });
}

export function useUpdateProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateProject,
        onSuccess: async (response: Project) => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.projects._def });
            await queryClient.invalidateQueries(queryKeys.engagements.byEngagementId(response.engagementId));
            await queryClient.invalidateQueries(queryKeys.projects.byEngagementId(response.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to save project"),
    });
}

export function useProject(engagementId: string | null | undefined, projectId: string | null | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.projects.byProjectId(projectId!),
        enabled: !!engagementId && !!projectId && enabled,
        queryFn: () => getProject({ engagementId: engagementId!, projectId: projectId! }),
    });
}

export function useRequestAccessToProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: requestAccessToProject,
        onSuccess: async (response: unknown, args: RequestAccessToProjectArgs) => {
            await queryClient.invalidateQueries(queryKeys.projects.byEngagementId(args.engagementId));
            await queryClient.invalidateQueries({ queryKey: queryKeys.projects.search._def });
        },
        ...mutationErrorHandler(toast, "Unable to request access"),
    });
}

export function useCloseOrReopenProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: closeOrReopenProject,
        onSuccess: async (response: CloseOrReopenProjectResponse, args: CloseOrReopenProjectArgs) => {
            await queryClient.invalidateQueries(queryKeys.projects.byProjectId(args.projectId));
            await queryClient.invalidateQueries(queryKeys.roles.myRoleByProjectId(args.projectId));
        },
        ...mutationErrorHandler(toast, "Unable to close or reopen project"),
    });
}

export function useEngagementDashboardSummary(engagement: Engagement, projects: Project[]) {
    const engagementId = engagement.engagementId;
    const engagementProjectIdsStr = getEngagementProjectIdsStr(engagement, projects);
    const projectIdsInOtherRegions = getProjectIdsNotInEngagementRegion(engagement, projects);

    const queryKey = (engagementProjectIdsStr || "") + projectIdsInOtherRegions.join("");

    return useQuery({
        ...queryKeys.engagements.summaryByEngagementId(engagementId, queryKey),
        enabled: !!engagementId,
        queryFn: async () => {
            const data = await Promise.all([
                getEngagementSummary({ engagementId, projectIds: engagementProjectIdsStr }),
                ...projectIdsInOtherRegions.map(x => getEngagementSummaryForRegion({ engagementId, projectId: x.projectId, projectIds: x.ids })),
            ]);
            const engagmentSummary = data.reduce((tot, sum) => {
                if (tot) {
                    tot.requestsFulfilled += sum.requestsFulfilled;
                    tot.requestsFuture += sum.requestsFuture;
                    tot.requestsOverdue += sum.requestsOverdue;
                    tot.requestsThisWeek += sum.requestsThisWeek;
                    tot.requestsTwoWeeks += sum.requestsTwoWeeks;
                    tot.requestsTotal += sum.requestsTotal;
                }
                return tot;
            });

            return engagmentSummary;
        },
    });
}

//Project Summary
export function useProjectSummary(engagementId: string, projectId: string) {
    return useQuery({
        ...queryKeys.projects.summaryByProjectId(engagementId, projectId),
        enabled: !!engagementId && !!projectId,
        queryFn: () => getProjectSummary({ engagementId, projectId }),
    });
}

//Client Search
export function useClientSearchQueryFn() {
    const queryClient = useQueryClient();
    return async function (searchArgs: ClientSearchArgs): Promise<ClientSearchResponse[]> {
        return queryClient.fetchQuery({ ...queryKeys.clients.search(searchArgs), queryFn: () => searchClients(searchArgs) });
    };
}

//Engagement Search
export function useEngagementSearchQueryFn() {
    const queryClient = useQueryClient();
    return async function (searchArgs: EngagementSearchArgs): Promise<EngagementSearchResponse[]> {
        return queryClient.fetchQuery({ ...queryKeys.engagements.search(searchArgs), queryFn: () => searchEngagements(searchArgs) });
    };
}

//Project Search
export function useProjectSearchQueryFn() {
    const queryClient = useQueryClient();
    return async function (searchArgs: ProjectSearchArgs): Promise<ProjectSearchResponse[]> {
        return queryClient.fetchQuery({ ...queryKeys.projects.search(searchArgs), queryFn: () => searchProjects(searchArgs) });
    };
}

//User project search
export function useUserProjectSearchQueryFn() {
    const queryClient = useQueryClient();
    return async function (searchArgs: SearchUserProjectsArgs): Promise<UserProjectSummaryResponse[]> {
        return queryClient.fetchQuery({ ...queryKeys.projects.userProject(searchArgs), queryFn: () => searchUserProject(searchArgs) });
    };
}

//Engagement Rollup
export function useEngagementRollup() {
    return useQuery({ ...queryKeys.engagements.rollup, queryFn: getEngagementRollup });
}

//Invalidation Helpers
async function invalidateEngagementActivity(queryClient: QueryClient, engagementId: string) {
    await queryClient.invalidateQueries({ queryKey: [...queryKeys.engagementActivity.search._def, engagementId] });
}

async function invalidateProjectActivity(queryClient: QueryClient, engagementId: string, projectId: string) {
    await queryClient.invalidateQueries({ queryKey: [...queryKeys.projectActivity.search._def, engagementId, projectId] });
}

async function invalidateEngagementEvents(queryClient: QueryClient, engagementId: string) {
    await queryClient.invalidateQueries({ queryKey: [...queryKeys.engagementEvents.search._def, engagementId] });
}

async function invalidateProjectEvents(queryClient: QueryClient, engagementId: string, projectId: string) {
    await queryClient.invalidateQueries({ queryKey: [...queryKeys.projectEvents.search._def, engagementId, projectId] });
}

export async function invalidateEngagementRequestQueries(queryClient: QueryClient, engagementId: string, requestId: string, isDelete?: boolean) {
    if (isDelete) {
        await queryClient.removeQueries({ queryKey: [...queryKeys.engagementRequests.byEngagementId._def, engagementId] });
    } else {
        await queryClient.invalidateQueries({ queryKey: [...queryKeys.engagementRequests.byEngagementId._def, engagementId] });
    }
    await queryClient.invalidateQueries(queryKeys.engagementRequests.byRequestId(engagementId, requestId));
    await queryClient.invalidateQueries(queryKeys.engagements.rollup);
}

export async function invalidateProjectRequestQueries(
    queryClient: QueryClient,
    engagementId: string,
    projectId: string,
    requestId: string,
    isDelete?: boolean,
) {
    if (isDelete) {
        await queryClient.removeQueries({ queryKey: [...queryKeys.projectRequests.byProjectId._def, engagementId, projectId] });
    } else {
        await queryClient.invalidateQueries({ queryKey: [...queryKeys.projectRequests.byProjectId._def, engagementId, projectId] });
    }
    await queryClient.invalidateQueries(queryKeys.projectRequests.byRequestId(engagementId, projectId, requestId));
}

async function invalidateEngagementQueries(queryClient: QueryClient, engagementId: string) {
    await queryClient.invalidateQueries({ queryKey: [...queryKeys.engagements.summaryByEngagementId._def, engagementId] });
    await queryClient.invalidateQueries(queryKeys.engagements.rollup);
    await invalidateEngagementActivity(queryClient, engagementId);
}

async function invalidateProjectQueries(queryClient: QueryClient, engagementId: string, projectId: string) {
    await queryClient.invalidateQueries({ queryKey: [...queryKeys.projects.summaryByProjectId._def, engagementId, projectId] });
    await queryClient.invalidateQueries(queryKeys.engagements.rollup);
    await invalidateProjectActivity(queryClient, engagementId, projectId);
}

//Engagement Events
export function useEngagementEvent(engagementId: string, eventId: string, enabled = true) {
    return useQuery({
        ...queryKeys.engagementEvents.byEventId(engagementId, eventId),
        enabled: enabled && !!engagementId && !!eventId && eventId !== PLACEHOLDER_FOR_ID_NOT_CREATED,
        queryFn: () => getEngagementEvent({ engagementId, eventId }),
    });
}

export function useSaveEngagementEvent() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostEngagementEventArgs | PutEngagementEventArgs) => {
            if ((args as PutEngagementEventArgs).eventId) {
                return putEngagementEvent(args as PutEngagementEventArgs);
            } else {
                return postEngagementEvent(args);
            }
        },
        onSuccess: async (eventResponse: Event) => {
            await queryClient.invalidateQueries(queryKeys.engagementEvents.byEventId(eventResponse.engagementId, eventResponse.eventId));
            await invalidateEngagementActivity(queryClient, eventResponse.engagementId);
            await invalidateEngagementEvents(queryClient, eventResponse.engagementId);
            return eventResponse;
        },
        ...mutationErrorHandler(toast, "Unable to save event"),
    });
}

export function useDeleteEngagementEvent() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteEngagementEventArgs) => {
            return deleteEngagementEvent(args);
        },
        onSuccess: async (response: unknown, args: DeleteEngagementEventArgs) => {
            await queryClient.invalidateQueries(queryKeys.engagementEvents.byEventId(args.engagementId, args.eventId));
            await invalidateEngagementActivity(queryClient, args.engagementId);
            await invalidateEngagementEvents(queryClient, args.engagementId);
            return args;
        },
        ...mutationErrorHandler(toast, "Unable to delete event"),
    });
}

export function useEngagementDashboardEventSearch(engagement: Engagement, projects: Project[], startDate: string | undefined, endDate: string | undefined) {
    const queryClient = useQueryClient();
    const engagementId = engagement.engagementId || "";
    const engagementProjectIdsStr = getEngagementProjectIdsStr(engagement, projects);
    const projectIdsInOtherRegions = getProjectIdsNotInEngagementRegion(engagement, projects);

    return useQuery({
        ...queryKeys.engagementEvents.search(engagementId, {
            startDate,
            endDate,
            projectIds: (engagementProjectIdsStr || "") + projectIdsInOtherRegions.join(""),
        }),
        queryFn: async () => {
            const data = await Promise.all([
                searchEngagementEvents({ engagementId, startDate, endDate, projectIds: engagementProjectIdsStr }),
                ...projectIdsInOtherRegions.map(x =>
                    searchEngagementEventsForRegion({ engagementId, projectId: x.projectId, startDate, endDate, projectIds: x.ids }),
                ),
            ]);

            const totRequests: Request[] = [];
            const totEvents: Event[] = [];

            for (let i = 0; i < data.length; i++) {
                const curr = data[i];
                if (curr.requests && curr.requests.length > 0) {
                    for (let y = 0; y < curr.requests.length; y++) {
                        const request = curr.requests[y];
                        if (!totRequests.find(a => a.requestId === request.requestId)) {
                            totRequests.push(request);
                        }
                    }
                }
                if (curr.events && curr.events.length > 0) {
                    for (let y = 0; y < curr.events.length; y++) {
                        const event = curr.events[y];
                        if (!totEvents.find(a => a.eventId === event.eventId)) {
                            totEvents.push(event);
                        }
                    }
                }
            }

            if (totRequests) {
                totRequests.forEach(request =>
                    queryClient.setQueryData<Request>(queryKeys.engagementRequests.byRequestId(request.engagementId, request.requestId).queryKey, request),
                );
            }
            if (totEvents) {
                totEvents.forEach(event =>
                    queryClient.setQueryData<Event>(queryKeys.engagementEvents.byEventId(event.engagementId, event.eventId).queryKey, event),
                );
            }
            return { requests: totRequests, events: totEvents };
        },
        enabled: !!engagementId,
        ...errorMessage("Unable to load calendar events"),
    });
}

export function useEngagementEventSearch(searchArgs: EngagementEventSearchArgs | null) {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.engagementEvents.search(searchArgs?.engagementId || "", { ...searchArgs! }),
        queryFn: async () => {
            const engagementEventSearchResults = await searchEngagementEvents({ ...searchArgs! });
            if (engagementEventSearchResults.requests) {
                engagementEventSearchResults.requests.forEach(request =>
                    queryClient.setQueryData<Request>(queryKeys.engagementRequests.byRequestId(request.engagementId, request.requestId).queryKey, request),
                );
            }
            if (engagementEventSearchResults.events) {
                engagementEventSearchResults.events.forEach(event =>
                    queryClient.setQueryData<Event>(queryKeys.engagementEvents.byEventId(event.engagementId, event.eventId).queryKey, event),
                );
            }
            return engagementEventSearchResults;
        },
        enabled: !!searchArgs?.engagementId,
        ...errorMessage("Unable to load calendar events"),
    });
}

export function useBulkUploadEngagementEventsToCalendar() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: bulkUploadEngagementEvents,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.engagementEvents._def });
        },
        ...mutationErrorHandler(toast, "Unable to add events", { preferFallbackMessage: false }),
    });
}

//Project Events
export function useProjectEvent(engagementId: string, projectId: string, eventId: string, enabled = true) {
    return useQuery({
        ...queryKeys.projectEvents.byEventId(engagementId, projectId, eventId),
        enabled: enabled && !!engagementId && !!eventId && eventId !== PLACEHOLDER_FOR_ID_NOT_CREATED,
        queryFn: () => getProjectEvent({ engagementId, projectId, eventId }),
    });
}

export function useSaveProjectEvent() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostProjectEventArgs | PutProjectEventArgs) => {
            if ((args as PutProjectEventArgs).eventId) {
                return putProjectEvent(args as PutProjectEventArgs);
            } else {
                return postProjectEvent(args);
            }
        },
        onSuccess: async (eventResponse: Event) => {
            if (eventResponse.projectId) {
                await queryClient.invalidateQueries(
                    queryKeys.projectEvents.byEventId(eventResponse.engagementId, eventResponse.projectId, eventResponse.eventId),
                );
                await invalidateProjectActivity(queryClient, eventResponse.engagementId, eventResponse.projectId);
                await invalidateProjectEvents(queryClient, eventResponse.engagementId, eventResponse.projectId);
            }
            return eventResponse;
        },
        ...mutationErrorHandler(toast, "Unable to save event"),
    });
}

export function useDeleteProjectEvent() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteProjectEventArgs) => {
            return deleteProjectEvent(args);
        },
        onSuccess: async (response: unknown, args: DeleteProjectEventArgs) => {
            await queryClient.invalidateQueries(queryKeys.projectEvents.byEventId(args.engagementId, args.projectId, args.eventId));
            await invalidateEngagementActivity(queryClient, args.engagementId);
            await invalidateProjectEvents(queryClient, args.engagementId, args.projectId);
            return args;
        },
        ...mutationErrorHandler(toast, "Unable to delete event"),
    });
}

export function useProjectEventSearch(searchArgs: ProjectEventSearchArgs | null) {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.projectEvents.search(searchArgs?.engagementId || "", searchArgs?.projectId || "", searchArgs || {}),
        queryFn: async () => {
            const projectEventSearchResults = await searchProjectEvents({ ...searchArgs! });
            if (projectEventSearchResults.requests) {
                projectEventSearchResults.requests.forEach(request =>
                    queryClient.setQueryData<Request>(
                        queryKeys.projectRequests.byRequestId(request.engagementId, request.projectId!, request.requestId).queryKey,
                        request,
                    ),
                );
            }
            if (projectEventSearchResults.events) {
                projectEventSearchResults.events.forEach(event =>
                    queryClient.setQueryData<Event>(queryKeys.projectEvents.byEventId(event.engagementId, event.projectId!, event.eventId).queryKey, event),
                );
            }
            return projectEventSearchResults;
        },
        enabled: !!searchArgs?.engagementId,
        ...errorMessage("Unable to load calendar events"),
    });
}

export function useBulkUploadProjectEventsToCalendar() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: bulkUploadProjectEvents,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.projectEvents._def });
        },
        ...mutationErrorHandler(toast, "Unable to add events", { preferFallbackMessage: false }),
    });
}

// Engagement Requests
export function useEngagementRequests(engagementId: string) {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.engagementRequests.byEngagementId(engagementId, ""),
        enabled: !!engagementId,
        queryFn: async () => {
            const requests = await getEngagementRequestsByEngagementId({ engagementId });
            requests.forEach(request =>
                queryClient.setQueryData<Request>(queryKeys.engagementRequests.byRequestId(engagementId, request.requestId).queryKey, request),
            );
            return requests;
        },
    });
}

export function useEngagmentDashboardRequests(engagement: Engagement, projects: Project[]) {
    const queryClient = useQueryClient();

    const engagementId = engagement.engagementId;
    const engagementProjectIdsStr = getEngagementProjectIdsStr(engagement, projects);
    const projectIdsInOtherRegions = getProjectIdsNotInEngagementRegion(engagement, projects);

    const queryKey = (engagementProjectIdsStr || "") + projectIdsInOtherRegions.join("");

    return useQuery({
        ...queryKeys.engagementRequests.byEngagementId(engagementId, queryKey),
        enabled: !!engagementId,
        queryFn: async () => {
            const data = await Promise.all([
                getEngagementRequestsByEngagementId({ engagementId, projectIds: engagementProjectIdsStr }),
                ...projectIdsInOtherRegions.map(x => getEngagementRequestsByEngagementIdForRegion({ engagementId, projectId: x.projectId, projectIds: x.ids })),
            ]);
            const requests = data.flat();
            const uniqueRequests = [...new Map(requests.map(item => [item.requestId, item])).values()];
            uniqueRequests.forEach((request: Request) =>
                queryClient.setQueryData<Request>(queryKeys.engagementRequests.byRequestId(engagementId, request.requestId).queryKey, request),
            );
            return uniqueRequests;
        },
    });
}

export function useEngagementRequest(engagementId: string, requestId: string, enabled = true) {
    return useQuery({
        ...queryKeys.engagementRequests.byRequestId(engagementId, requestId),
        enabled: !!engagementId && !!requestId && requestId !== PLACEHOLDER_FOR_ID_NOT_CREATED && enabled,
        queryFn: () => getEngagementRequest({ engagementId, requestId }),
    });
}

export function useSaveEngagementRequest(deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostEngagementRequestArgs | PutEngagementRequestArgs) => {
            if ((args as PutEngagementRequestArgs).requestId) {
                return putEngagementRequest(args as PutEngagementRequestArgs);
            } else {
                return postEngagementRequest(args);
            }
        },

        onSuccess: async (response: Request) => {
            await invalidateEngagementQueries(queryClient, response.engagementId);
            await invalidateEngagementEvents(queryClient, response.engagementId);
            if (!deferCacheInvalidation) {
                await invalidateEngagementRequestQueries(queryClient, response.engagementId, response.requestId);
            }
        },
        ...mutationErrorHandler(toast, "Unable to save request"),
    });
}

export function useDeleteEngagementRequest() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteEngagementRequestArgs) => {
            await deleteEngagementRequest(args);
            return args;
        },

        onSuccess: async (args: DeleteEngagementRequestArgs) => {
            await invalidateEngagementQueries(queryClient, args.engagementId);
            await invalidateEngagementEvents(queryClient, args.engagementId);
            invalidateEngagementRequestQueries(queryClient, args.engagementId, args.requestId, true);
        },
        ...mutationErrorHandler(toast, "Unable to delete request"),
    });
}

export function useUploadDocumentToEngagementRequest(deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: uploadDocumentToEngagementRequest,
        onSuccess: async (response: Document, args: UploadDocumentsToEngagementRequestArgs) => {
            await invalidateEngagementQueries(queryClient, args.engagementId);
            if (!deferCacheInvalidation) {
                await invalidateEngagementRequestQueries(queryClient, args.engagementId, args.requestId);
            }
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

export function useBulkUploadEngagementRequests() {
    const toast = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: bulkUploadEngagementRequests,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.engagementRequests._def });
            await queryClient.invalidateQueries(queryKeys.engagements.rollup);
        },
        ...mutationErrorHandler(toast, "Requests couldn't be uploaded", { preferFallbackMessage: false }),
    });
}

// Project Requests
export function useProjectRequests(engagementId: string, projectId: string) {
    const queryClient = useQueryClient();
    return useQuery({
        ...queryKeys.projectRequests.byProjectId(engagementId, projectId),
        enabled: !!engagementId && !!projectId,
        queryFn: async () => {
            const requests = await getProjectRequestsByProjectId({ engagementId, projectId });
            requests.forEach(request =>
                queryClient.setQueryData<Request>(queryKeys.projectRequests.byRequestId(engagementId, projectId, request.requestId).queryKey, request),
            );
            return requests;
        },
    });
}

export function useProjectRequest(engagementId: string, projectId: string, requestId: string, enabled = true) {
    return useQuery({
        ...queryKeys.projectRequests.byRequestId(engagementId, projectId, requestId),
        enabled: !!engagementId && !!projectId && !!requestId && requestId !== PLACEHOLDER_FOR_ID_NOT_CREATED && enabled,
        queryFn: () => getProjectRequest({ engagementId, projectId, requestId }),
    });
}

export function useSaveProjectRequest(deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostProjectRequestArgs | PutProjectRequestArgs) => {
            if ((args as PutProjectRequestArgs).requestId) {
                return putProjectRequest(args as PutProjectRequestArgs);
            } else {
                return postProjectRequest(args);
            }
        },

        onSuccess: async (response: Request) => {
            if (response.projectId) {
                await invalidateProjectQueries(queryClient, response.engagementId, response.projectId);
                await invalidateProjectEvents(queryClient, response.engagementId, response.projectId);
                if (!deferCacheInvalidation) {
                    await invalidateProjectRequestQueries(queryClient, response.engagementId, response.projectId, response.requestId);
                }
            }
        },
        ...mutationErrorHandler(toast, "Unable to save request"),
    });
}

export function useDeleteProjectRequest() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteProjectRequestArgs) => {
            return await deleteProjectRequest(args);
        },

        onSuccess: async (response: unknown, args: DeleteProjectRequestArgs) => {
            await invalidateProjectQueries(queryClient, args.engagementId, args.projectId);
            await invalidateProjectEvents(queryClient, args.engagementId, args.projectId);
            invalidateProjectRequestQueries(queryClient, args.engagementId, args.projectId, args.requestId, true);
        },
        ...mutationErrorHandler(toast, "Unable to delete request"),
    });
}

export function useUploadDocumentToProjectRequest(deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: uploadDocumentToProjectRequest,
        onSuccess: async (response: Document, args: UploadDocumentsToProjectRequestArgs) => {
            await invalidateProjectQueries(queryClient, args.engagementId, args.projectId);
            if (!deferCacheInvalidation) {
                await invalidateProjectRequestQueries(queryClient, args.engagementId, args.projectId, args.requestId);
            }
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

export function useBulkUploadProjectRequests() {
    const toast = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: bulkUploadProjectRequests,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: queryKeys.projectRequests._def });
            await queryClient.invalidateQueries(queryKeys.engagements.rollup);
        },
        ...mutationErrorHandler(toast, "Requests couldn't be uploaded", { preferFallbackMessage: false }),
    });
}

// Engagement Documents
export function useEngagementDocuments(engagementId: string, rootFolderId: string | undefined, folderId: string | undefined) {
    return useQuery({
        ...queryKeys.engagementDocuments.documentsByFolder(engagementId, folderId!),
        queryFn: () => {
            return getEngagementDocuments({ engagementId, rootFolderId: rootFolderId!, folderId: folderId! });
        },

        gcTime: Infinity, // to save on box api calls. user has to refresh the page to see new documents added externally.
        staleTime: Infinity,
        enabled: !!engagementId && !!rootFolderId && !!folderId,
    });
}

export function useDeleteEngagementDocument(engagementId: string, folderId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (documentId: string) => {
            return await deleteEngagementDocument({ engagementId, documentId });
        },

        onSuccess: async () => {
            await invalidateEngagementQueries(queryClient, engagementId);
            return invalidateDocumentCacheForEngagementAndFolder(queryClient, engagementId, folderId);
        },
        ...mutationErrorHandler(toast, "Unable to delete document from engagement"),
    });
}

export function useCreateEngagementFolders(engagementId: string, folderId: string, deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (folderPaths: string[]) => {
            return await createEngagementFolders({ engagementId, folderId, folderPaths });
        },

        onSuccess: async () => {
            if (!deferCacheInvalidation) {
                await invalidateDocumentCacheForEngagementAndFolder(queryClient, engagementId, folderId);
            }
        },
        ...mutationErrorHandler(toast, "Unable to create folders"),
    });
}

export function useRenameEngagementFolder(engagementId: string, folderId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async ({ folderId, newFolderName }: { folderId: string; newFolderName: string }) => {
            await renameEngagementFolder({ engagementId, folderId, name: newFolderName });
        },
        onSuccess: async () => {
            await invalidateDocumentCacheForEngagementAndFolder(queryClient, engagementId, folderId);
        },
        ...mutationErrorHandler(toast, "Unable to rename folder"),
    });
}

export function useDeleteEngagementFolder(engagementId: string, folderId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (folderId: string) => {
            await deleteEngagementFolder({ engagementId, folderId });
        },
        onSuccess: async () => {
            await invalidateDocumentCacheForEngagementAndFolder(queryClient, engagementId, folderId);
        },
        onError: (error: unknown) => {
            const errorResponse = error as ErrorResponse;
            handleError(toast, errorResponse, errorResponse.status === 412 ? "Folder is not empty" : "Unable to delete folder", {
                preferFallbackMessage: true,
            });
        },
    });
}

export function useUploadEngagementDocument(engagementId: string, deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async ({ folderId, fileContainer }: { fileContainer: FileContainer; folderId: string }) => {
            return await uploadEngagementDocument({ engagementId, folderId, fileContainer });
        },
        onSuccess: async (data, { folderId }: { fileContainer: FileContainer; folderId: string }) => {
            await invalidateEngagementQueries(queryClient, engagementId);
            if (!deferCacheInvalidation) {
                await invalidateDocumentCacheForEngagementAndFolder(queryClient, engagementId, folderId);
            }
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

export function useEngagementDocumentPreviewLink(engagementId: string, documentId: string | undefined) {
    return useQuery({
        ...queryKeys.engagementDocuments.documentPreviewLink(engagementId, documentId),
        enabled: !!engagementId && !!documentId,
        queryFn: () => getEngagementDocumentPreviewLink({ engagementId, documentId: documentId! }),
    });
}

export function useEngagementBoxFolderLink(engagementId: string, folderId: string | undefined) {
    return useQuery({
        ...queryKeys.engagementDocuments.documentBoxLink(engagementId, folderId),
        enabled: !!engagementId && !!folderId,
        queryFn: () => getEngagementBoxFolderLink({ engagementId, folderId: folderId! }),
    });
}

export async function invalidateDocumentCacheForEngagementAndFolder(queryClient: QueryClient, engagementId: string, folderId: string) {
    await queryClient.invalidateQueries(queryKeys.engagementDocuments.documentsByFolder(engagementId, folderId));
    await queryClient.invalidateQueries(queryKeys.folders.byId(engagementId));
}

// Project Documents
export function useProjectDocuments(engagementId: string, projectId: string, rootFolderId: string | undefined, folderId: string | undefined) {
    return useQuery({
        ...queryKeys.projectDocuments.documentsByFolder(engagementId, projectId, folderId ?? ""),
        queryFn: () => {
            return getProjectDocuments({ engagementId, projectId, rootFolderId: rootFolderId!, folderId: folderId! });
        },

        gcTime: Infinity, // to save on box api calls. user has to refresh the page to see new documents added externally.
        staleTime: Infinity,
        enabled: !!engagementId && !!projectId && !!rootFolderId && !!folderId,
    });
}

export function useDeleteProjectDocument(engagementId: string, projectId: string, folderId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (documentId: string) => {
            return await deleteProjectDocument({ engagementId, projectId, documentId });
        },

        onSuccess: async () => {
            await invalidateProjectQueries(queryClient, engagementId, projectId);
            return invalidateDocumentCacheForProjectAndFolder(queryClient, engagementId, projectId, folderId);
        },
        ...mutationErrorHandler(toast, "Unable to delete document from project"),
    });
}

export function useCreateProjectFolders(engagementId: string, projectId: string, folderId: string, deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (folderPaths: string[]) => {
            return await createProjectFolders({ engagementId, projectId, folderId, folderPaths });
        },

        onSuccess: async () => {
            if (!deferCacheInvalidation) {
                await invalidateDocumentCacheForProjectAndFolder(queryClient, engagementId, projectId, folderId);
            }
        },
        ...mutationErrorHandler(toast, "Unable to create folders"),
    });
}

export function useRenameProjectFolder(engagementId: string, projectId: string, folderId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async ({ folderId, newFolderName }: { folderId: string; newFolderName: string }) => {
            await renameProjectFolder({ engagementId, projectId, folderId, name: newFolderName });
        },
        onSuccess: async () => {
            await invalidateDocumentCacheForProjectAndFolder(queryClient, engagementId, projectId, folderId);
        },
        ...mutationErrorHandler(toast, "Unable to rename folder"),
    });
}

export function useDeleteProjectFolder(engagementId: string, projectId: string, folderId: string) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (folderId: string) => {
            await deleteProjectFolder({ engagementId, projectId, folderId });
        },
        onSuccess: async () => {
            await invalidateDocumentCacheForProjectAndFolder(queryClient, engagementId, projectId, folderId);
        },
        onError: (error: unknown) => {
            const errorResponse = error as ErrorResponse;
            handleError(toast, errorResponse, errorResponse.status === 412 ? "Folder is not empty" : "Unable to delete folder", {
                preferFallbackMessage: true,
            });
        },
    });
}

export function useUploadProjectDocument(engagementId: string, projectId: string, deferCacheInvalidation = false) {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async ({ folderId, fileContainer }: { fileContainer: FileContainer; folderId: string }) => {
            return await uploadProjectDocument({ engagementId, projectId, folderId, fileContainer });
        },
        onSuccess: async (data, { folderId }: { fileContainer: FileContainer; folderId: string }) => {
            await invalidateProjectQueries(queryClient, engagementId, projectId);
            if (!deferCacheInvalidation) {
                await invalidateDocumentCacheForProjectAndFolder(queryClient, engagementId, projectId, folderId);
            }
        },
        ...mutationErrorHandler(toast, "Unable to upload document"),
    });
}

export function useProjectDocumentPreviewLink(engagementId: string, projectId: string, documentId: string | undefined) {
    return useQuery({
        ...queryKeys.projectDocuments.documentPreviewLink(engagementId, projectId, documentId),
        enabled: !!engagementId && !!projectId && !!documentId,
        queryFn: () => getProjectDocumentPreviewLink({ engagementId, projectId, documentId: documentId! }),
    });
}

export function useProjectBoxFolderLink(engagementId: string, projectId: string, folderId: string | undefined) {
    return useQuery({
        ...queryKeys.projectDocuments.documentBoxLink(engagementId, projectId, folderId),
        enabled: !!engagementId && !!projectId && !!folderId,
        queryFn: () => getProjectBoxFolderLink({ engagementId, projectId, folderId: folderId! }),
    });
}

export async function invalidateDocumentCacheForProjectAndFolder(queryClient: QueryClient, engagementId: string, projectId: string, folderId: string) {
    await queryClient.invalidateQueries(queryKeys.projectDocuments.documentsByFolder(engagementId, projectId, folderId));
    await queryClient.invalidateQueries(queryKeys.folders.byId(engagementId, projectId));
}

export function useRequestStatuses() {
    return useQuery({
        ...queryKeys.requestStatuses.all,
        queryFn: getRequestStatuses,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load request statuses"),
    });
}

export function useRequestTypes() {
    return useQuery({
        ...queryKeys.requestTypes.all,
        queryFn: getRequestTypes,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load request types"),
    });
}

export function useStates() {
    return useQuery({ ...queryKeys.states.all, queryFn: getStates, gcTime: Infinity, staleTime: Infinity, ...errorMessage("Unable to load states") });
}

// Engagement Activity

export function useEngagementActivityTypes(args: GetEngagementActivityTypesArgs) {
    return useQuery({
        ...queryKeys.engagementActivityTypes.all,
        enabled: !!args.engagementId,
        queryFn: () => getEngagementActivityTypes(args),
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load activity types"),
    });
}

export function useEngagementActivitySearchQueryFn() {
    const queryClient = useQueryClient();
    return async function (searchArgs: EngagementActivitySearchArgs): Promise<ActivitySearchResponse> {
        return queryClient.fetchQuery({
            ...queryKeys.engagementActivity.search(searchArgs.engagementId, searchArgs),
            queryFn: () => searchEngagementActivities(searchArgs),
        });
    };
}

// Project Activity

export function useProjectActivityTypes(args: GetProjectActivityTypesArgs) {
    return useQuery({
        ...queryKeys.projectActivityTypes.all,
        enabled: !!args.engagementId && !!args.projectId,
        queryFn: () => getProjectActivityTypes(args),
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load activity types"),
    });
}

export function useProjectActivitySearchQueryFn() {
    const queryClient = useQueryClient();
    return async function (searchArgs: ProjectActivitySearchArgs): Promise<ActivitySearchResponse> {
        return queryClient.fetchQuery({
            ...queryKeys.projectActivity.search(searchArgs.engagementId, searchArgs.projectId, searchArgs),
            queryFn: () => searchProjectActivities(searchArgs),
        });
    };
}

//Users

export function useCurrentUser() {
    return useQuery({ ...queryKeys.users.current, queryFn: getCurrentUser });
}

export function useDashboardUsers(engagementId: string, projectIds: string[]) {
    const projectIdsStr = projectIds.join(",");
    return useQuery({
        ...queryKeys.users.byEngagementId(engagementId, projectIdsStr),
        enabled: !!engagementId,
        queryFn: () => getDashboardUsers({ engagementId, projectIds: projectIdsStr }),
    });
}

export function useEngagementUsers(engagementId: string, enabled = true) {
    return useQuery({
        ...queryKeys.users.byEngagementId(engagementId),
        enabled: enabled && !!engagementId,
        queryFn: () => getEngagementUsers({ engagementId }),
    });
}

export function useProjectUsers(engagementId: string, projectId: string) {
    return useQuery({
        ...queryKeys.users.byProjectId(engagementId, projectId),
        enabled: !!engagementId && !!projectId,
        queryFn: () => getProjectUsers({ engagementId, projectId }),
    });
}

export function useRoles() {
    return useQuery({ ...queryKeys.roles.all, queryFn: getRoles, gcTime: Infinity, staleTime: Infinity, ...errorMessage("Unable to fetch roles") });
}

export function useMyRoleOnEngagement(engagementId: string | null | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.roles.myRoleByEngagementId(engagementId!),
        queryFn: () => {
            return getMyRoleOnEngagement({ engagementId: engagementId! });
        },
        enabled: enabled && !!engagementId,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to fetch your role on current engagement"),
    });
}

export function useMyRoleOnProject(engagementId: string | null | undefined, projectId: string | null | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.roles.myRoleByProjectId(projectId!),
        enabled: enabled && !!engagementId && !!projectId,
        queryFn: () => getMyRoleOnProject({ engagementId: engagementId!, projectId: projectId! }),
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to fetch your role on current project"),
    });
}

export type PermissionsByRole = Record<number, Set<string> | undefined>;
export function usePermissionsByRole(enabled = true) {
    return useQuery({
        ...queryKeys.permissions.all,
        queryFn: async (): Promise<PermissionsByRole> => {
            const response: PermissionsByRoleResponse = await getPermissionsByRole();
            const permissionsByRole: Record<number, Set<string>> = {};
            Object.entries(response).forEach(([roleId, permissions]) => {
                permissionsByRole[parseInt(roleId)] = new Set(permissions || []);
            });
            return permissionsByRole;
        },
        enabled,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to fetch application permissions"),
    });
}

export function useSearchUsersQueryFn() {
    const queryClient = useQueryClient();

    return async function (args: SearchUsersArgs): Promise<User[]> {
        if (args.searchText && args.searchText.length > 1) {
            return queryClient.fetchQuery({ ...queryKeys.users.search(args), queryFn: () => searchUsers(args) });
        } else {
            return [];
        }
    };
}

// Engagement Users
export function useDeleteUserFromEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteUserFromEngagementArgs) => {
            await deleteUserFromEngagement(args);
            return args;
        },

        onSuccess: async (response: DeleteUserFromEngagementArgs) => {
            await invalidateEngagementActivity(queryClient, response.engagementId);
            return invalidateEngagementUsers(queryClient, response.engagementId);
        },
        ...mutationErrorHandler(toast, "Unable to delete user from engagement"),
    });
}

export function useAddExternalUserToEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: AddExternalUserToEngagementArgs) => {
            await addExternalUserToEngagement(args);
            return args;
        },

        onSuccess: async (response: AddExternalUserToEngagementArgs) => {
            await invalidateEngagementActivity(queryClient, response.engagementId);
            return invalidateEngagementUsers(queryClient, response.engagementId);
        },
        ...mutationErrorHandler(toast, "Unable to delete user from engagement"),
    });
}

export function useAddOrUpdateUserOnEngagement() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: AddUpdateUserOnEngagementArgs) => {
            await addOrUpdateUserOnEngagement(args);
            return args;
        },

        onSuccess: async (response: AddUpdateUserOnEngagementArgs) => {
            await invalidateEngagementActivity(queryClient, response.engagementId);
            return invalidateEngagementUsers(queryClient, response.engagementId);
        },
        ...mutationErrorHandler(toast, "Unable to add or edit user(s)", { preferFallbackMessage: false }),
    });
}
function invalidateEngagementUsers(queryClient: QueryClient, engagementId: string) {
    return queryClient.invalidateQueries({ queryKey: [...queryKeys.users.byEngagementId._def, engagementId] });
}

// Project Users
export function useDeleteUserFromProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: DeleteUserFromProjectArgs) => {
            await deleteUserFromProject(args);
            return args;
        },

        onSuccess: async (response: DeleteUserFromProjectArgs) => {
            await invalidateProjectActivity(queryClient, response.engagementId, response.projectId);
            return invalidateProjectUsers(queryClient, response.engagementId, response.projectId);
        },
        ...mutationErrorHandler(toast, "Unable to delete user from project"),
    });
}

export function useAddExternalUserToProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: AddExternalUserToProjectArgs) => {
            await addExternalUserToProject(args);
            return args;
        },

        onSuccess: async (response: AddExternalUserToProjectArgs) => {
            await invalidateProjectActivity(queryClient, response.engagementId, response.projectId);
            return invalidateProjectUsers(queryClient, response.engagementId, response.projectId);
        },
        ...mutationErrorHandler(toast, "Unable to delete user from project"),
    });
}

export function useAddOrUpdateUserOnProject() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: async (args: AddUpdateUserOnProjectArgs) => {
            await addOrUpdateUserOnProject(args);
            return args;
        },

        onSuccess: async (response: AddUpdateUserOnProjectArgs) => {
            await invalidateProjectActivity(queryClient, response.engagementId, response.projectId);
            return invalidateProjectUsers(queryClient, response.engagementId, response.projectId);
        },
        ...mutationErrorHandler(toast, "Unable to add to project", { preferFallbackMessage: false }),
    });
}

function invalidateProjectUsers(queryClient: QueryClient, engagementId: string, projectId: string) {
    return queryClient.invalidateQueries({ queryKey: [...queryKeys.users.byProjectId._def, engagementId, projectId] });
}

export type FeatureFlags = {
    boxIframeLinkEnabled: boolean;
};

export function useFeatureFlags() {
    return useQuery({
        ...queryKeys.featureFlags.all,
        queryFn: async (): Promise<FeatureFlags> => {
            const response: FeatureFlagResponse = await getFeatureFlags();
            return {
                boxIframeLinkEnabled: response.BoxIframeLinkEnabled === "True",
            } as FeatureFlags;
        },

        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load flags."),
    });
}

export function useNavItems() {
    return useQuery({ ...queryKeys.navItems.all, queryFn: getNavItems, gcTime: Infinity, staleTime: Infinity, ...errorMessage("Unable to fetch nav items") });
}

export function useNavItemsForEngagement(engagementId: string, enabled = true) {
    return useQuery({
        ...queryKeys.navItems.byEngagementId(engagementId),
        queryFn: () => getNavItemsByEngagement({ engagementId }),
        enabled: enabled && !!engagementId,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to fetch nav items for engagement"),
    });
}

export function useNavItemsForProject(engagementId: string, projectId: string, enabled = true) {
    return useQuery({
        ...queryKeys.navItems.byProjectId(engagementId, projectId),
        queryFn: () => getNavItemsByProject({ engagementId, projectId }),
        enabled: !!engagementId && !!projectId && enabled,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to fetch nav items for project"),
    });
}

// Global Notification Settings
export function useNotificationOptions() {
    return useQuery({
        ...queryKeys.notificationSettings.options,
        queryFn: getNotificationOptions,
        gcTime: Infinity,
        staleTime: Infinity,
        ...errorMessage("Unable to load notification options."),
    });
}

// export function useGlobalNotificationSettings() {
//     return useQuery({
//         ...queryKeys.notificationSettings.settings,
//         queryFn: getGlobalNotificationSettings,
//         ...errorMessage("Unable to load notification settings."),
//     });
// }

// export function useSaveGlobalNotificationSettings() {
//     const queryClient = useQueryClient();
//     const toast = useToast();
//     return useMutation({
//         mutationFn: updateGlobalNotificationSettings,
//         onSuccess: async data => {
//             await queryClient.setQueryData(queryKeys.notificationSettings.settings.queryKey, data);
//         },
//         ...mutationErrorHandler(toast, "Unable to update notification setting"),
//     });
// }

//Engagement Notification Settings
export function useEngagementNotificationSettings(engagementId: string) {
    return useQuery({
        ...queryKeys.notificationSettings.engagementSettings(engagementId),
        queryFn: () => getEngagementNotificationSettings({ engagementId }),
        enabled: !!engagementId,
        ...errorMessage("Unable to load notification settings."),
    });
}

//Engagement Level Notification Settings, this supersedes Global settings
// export function useEngagementLevelNotificationSettings(engagementId: string) {
//     return useQuery({
//         ...queryKeys.notificationSettings.engagementSettings(engagementId),
//         queryFn: () => getengagementLevelNotificationSetting({ engagementId}),
//         enabled: !!engagementId,
//         ...errorMessage("Unable to load engagement level notification settings."),
//     });
// }

//Update Engagement Level Notifications
// export function useUpdateEngagementNotificationSettings() {
//     const queryClient = useQueryClient();
//     const toast = useToast();
//     return useMutation({
//         mutationFn: updateEngagementLevelNotificationSetting,
//         onSuccess: async (data: EngagementProjectNotificationModel, args: UpdateEngagementLevelNotificationSettingArgs) => {
//             await queryClient.setQueryData(queryKeys.notificationSettings.engagementSettings(args.engagementId).queryKey, data);
//         },
//         ...mutationErrorHandler(toast, "Unable to update Engagement level notification setting"),
//     });
// }

export function useSaveEngagementNotificationSettings() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateEngagementNotificationSettings,
        onSuccess: async (data: NotificationSettings, args: UpdateEngagementNotificationSettingArgs) => {
            await queryClient.setQueryData(queryKeys.notificationSettings.engagementSettings(args.engagementId).queryKey, data);
        },
        ...mutationErrorHandler(toast, "Unable to update notification setting"),
    });
}

//Project Notification Settings
export function useProjectNotificationSettings(engagementId: string, projectId: string) {
    return useQuery({
        ...queryKeys.notificationSettings.projectSettings(engagementId, projectId),
        queryFn: () => getProjectNotificationSettings({ engagementId, projectId }),
        enabled: !!engagementId && !!projectId,
        ...errorMessage("Unable to load notification settings."),
    });
}

// export function useProjectLevelNotificationSetting(engagementId: string, projectId: string) {
//     return useQuery({
//         ...queryKeys.notificationSettings.projectSettings(engagementId, projectId),
//         queryFn: () => getprojectLevelNotificationSetting({ engagementId, projectId }),
//         enabled: !!engagementId && !!projectId,
//         ...errorMessage("Unable to load project level notification settings."),
//     });
// }

export function useSaveProjectNotificationSettings() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateProjectNotificationSettings,
        onSuccess: async (data: NotificationSettings, args: UpdateProjectNotificationSettingArgs) => {
            await queryClient.setQueryData(queryKeys.notificationSettings.projectSettings(args.engagementId, args.projectId).queryKey, data);
        },
        ...mutationErrorHandler(toast, "Unable to update notification setting"),
    });
}

export function useRequestConversations(requestId: string, engagementId: string, projectId?: string) {
    return useQuery({
        ...queryKeys.requestConversations.byRequestId(requestId),
        queryFn: () => getRequestConversations(requestId, engagementId, projectId),
        enabled: !!engagementId && !!requestId,
        ...errorMessage("Unable to load request-conversations."),
    });
}

export async function invalidateRequestConversationQueries(queryClient: QueryClient, requestId: string, engagementId: string, projectId?: string) {
    if (projectId) {
        await queryClient.invalidateQueries({ queryKey: [...queryKeys.projectRequests.byProjectId._def, engagementId, projectId] });
    } else {
        await queryClient.invalidateQueries({ queryKey: [...queryKeys.engagementRequests.byEngagementId._def, engagementId] });
    }
    await queryClient.invalidateQueries(queryKeys.requestConversations.byRequestId(requestId));
}

export function useCreateRequestConveration() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createRequestConversation,
        onSuccess: async (_data, args: CreateRequestConversationArgs) => {
            await invalidateRequestConversationQueries(queryClient, args.requestId, args.engagementId, args.projectId);
        },
        ...mutationErrorHandler(toast, "Unable to create request-conversation"),
    });
}

export function useDeleteRequestConveration() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: deleteRequestConversation,
        onSuccess: async (_data, args: DeleteRequestConversationArgs) => {
            await invalidateRequestConversationQueries(queryClient, args.requestId, args.engagementId, args.projectId);
        },
        ...mutationErrorHandler(toast, "Unable to delete request-conversation"),
    });
}

export function useCreateRequestComment() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createRequestComment,
        onSuccess: async (_data, args: CreateRequestCommentArgs) => {
            await queryClient.invalidateQueries(queryKeys.requestConversations.byRequestId(args.requestId));
        },
        ...mutationErrorHandler(toast, "Unable to create request-comment"),
    });
}

export function useDeleteRequestComment() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: deleteRequestComment,
        onSuccess: async (_data, args: DeleteRequestCommentArgs) => {
            await queryClient.invalidateQueries(queryKeys.requestConversations.byRequestId(args.requestId));
        },
        ...mutationErrorHandler(toast, "Unable to delete request-comment"),
    });
}

export function useTogglePublishRequestConveration() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: togglePublishConversation,
        onSuccess: async (_data, args: PublishRequestConversationArgs) => {
            await invalidateRequestConversationQueries(queryClient, args.requestId, args.engagementId, args.projectId);
        },
        ...mutationErrorHandler(toast, "Unable to toggle publish request-conversation"),
    });
}

export function useTogglePublishRequestComment() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: togglePublishComment,
        onSuccess: async (_data, args: PublishRequestCommentArgs) => {
            await queryClient.invalidateQueries(queryKeys.requestConversations.byRequestId(args.requestId));
        },
        ...mutationErrorHandler(toast, "Unable to toggle publish request-comment"),
    });
}

export function useGetProjectWorkspace(engagementId: string, projectId: string) {
    return useQuery({
        ...queryKeys.workspace.byProjId(engagementId, projectId),
        queryFn: () => getProjectWorkspace({ engagementId, projectId }),
    });
}

export function useGetEngagementWorkspace(engagementId: string) {
    return useQuery({
        ...queryKeys.workspace.byEngId(engagementId),
        queryFn: () => getEngagementWorkspace({ engagementId }),
    });
}

export function useCreateProjectWorkspace() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createProjectWorkspace,
        onSuccess: async (_response: Workspace, args: CreateProjectWorkspaceArgs) => {
            await queryClient.invalidateQueries(queryKeys.workspace.byProjId(args.engagementId, args.projectId));
        },
        ...mutationErrorHandler(toast, "Unable to enable reports"),
    });
}

export function useCreateEngagementWorkspace() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createEngagementWorkspace,
        onSuccess: async (_response: Workspace, args: CreateEngagementWorkspaceArgs) => {
            await queryClient.invalidateQueries(queryKeys.workspace.byEngId(args.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to enable reports"),
    });
}

export function useProjectReports(engagementId: string, projectId: string, enabled = true) {
    return useQuery({
        ...queryKeys.reports.byProjId(engagementId, projectId),
        enabled: enabled && !!engagementId && !!projectId,
        queryFn: () => getProjectReports({ engagementId, projectId }),
    });
}

export function useEngagementReports(engagementId: string, enabled = true) {
    return useQuery({
        ...queryKeys.reports.byEngId(engagementId),
        enabled: enabled && !!engagementId,
        queryFn: () => getEngagementReports({ engagementId }),
    });
}

export function useProjectCreateReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createProjectReport,
        onSuccess: async (_response: Report, args: UpdateProjectReportsArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byProjId(args.engagementId, args.projectId));
        },
        ...mutationErrorHandler(toast, "Unable to create report"),
    });
}

export function useEngagementCreateReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: createEngagementReport,
        onSuccess: async (_response: Report, args: UpdateEngagementReportsArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byEngId(args.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to create report"),
    });
}

export function useProjectUpdateReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateProjectReport,
        onSuccess: async (_response: Report, args: UpdateProjectReportsArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byProjId(args.engagementId, args.projectId));
        },
        ...mutationErrorHandler(toast, "Unable to update report"),
    });
}

export function useEngagementUpdateReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateEngagementReport,
        onSuccess: async (_response: Report, args: UpdateEngagementReportsArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byEngId(args.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to update report"),
    });
}

// export function useToggleReportPublish() {
//     const queryClient = useQueryClient();
//     const toast = useToast();
//     return useMutation({
//         mutationFn: updateProjectReport,
//         onSuccess: async (_response: Report, args: UpdateProjectReportsArgs) => {
//             await queryClient.invalidateQueries(queryKeys.reports.byId(args.engagementId, args.projectId));
//         },
//         ...mutationErrorHandler(toast, "Unable to update report"),
//     });
// }

// export function useEngagementToggleReportPublish() {
//     const queryClient = useQueryClient();
//     const toast = useToast();
//     return useMutation({
//         mutationFn: updateEngagementReport,
//         onSuccess: async (_response: Report, args: UpdateEngagementReportsArgs) => {
//             await queryClient.invalidateQueries(queryKeys.reports.byId(args.engagementId));
//         },
//         ...mutationErrorHandler(toast, "Unable to update report"),
//     });
// }

export function useProjectToggleReportDashboard() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateProjectReport,
        onSuccess: async (_response: Report, args: UpdateProjectReportsArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byProjId(args.engagementId, args.projectId));
        },
        ...mutationErrorHandler(toast, "Unable to update report"),
    });
}

export function useEngagementToggleReportDashboard() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateEngagementReport,
        onSuccess: async (_response: Report, args: UpdateEngagementReportsArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byEngId(args.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to update report"),
    });
}

export function useRemoveReport() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: removeReport,
        onSuccess: async (_response: Report, args: UpdateEngagementReportsArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byEngId(args.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to delete report"),
    });
}

export function useReportAuth() {
    const toast = useToast();
    return useMutation({
        mutationFn: authReport,
        ...mutationErrorHandler(toast, "Unable to get report auth-token"),
    });
}

export function useUploadEngagementReportFile() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: uploadEngagementReportFile,
        onSuccess: async (_response: unknown, args: EngagementReportFileArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byEngId(args.engagementId));
        },
        ...mutationErrorHandler(toast, "Unable to upload report"),
    });
}

export function useUploadProjectReportFile() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: uploadProjectReportFile,
        onSuccess: async (_response: unknown, args: ProjectReportFileArgs) => {
            await queryClient.invalidateQueries(queryKeys.reports.byProjId(args.engagementId, args.projectId));
        },
        ...mutationErrorHandler(toast, "Unable to upload report"),
    });
}

export function useUpdatePhoneNumber() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updatePhoneNumber,
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.users.current);
        },
        ...mutationErrorHandler(toast, "Unable to update phone number"),
    });
}

export function useFolderStructure(engagementId: string, projectId?: string | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.folders.byId(engagementId, projectId),
        enabled: enabled && !!engagementId,
        queryFn: () => {
            return projectId ? getProjectFolderStructure({ engagementId, projectId }) : getEngagementFolderStructure({ engagementId });
        },
    });
}

///
export function useApplications(engagementId: string, projectId?: string | undefined, enabled = true) {
    return useQuery({
        ...queryKeys.applications.byId(engagementId, projectId),
        enabled: enabled && !!engagementId,
        queryFn: () => getApplications({ engagementId, projectId }),
    });
}

export function useEnableApplication() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: enableApplication,
        onSuccess: async (_response: Application, args: ApplicationArgs & Application) => {
            await queryClient.invalidateQueries(queryKeys.applications.byId(args.engagementId, args.projectId));
            if (args.projectId) {
                await queryClient.invalidateQueries(queryKeys.navItems.byProjectId(args.engagementId, args.projectId));
            } else {
                await queryClient.invalidateQueries(queryKeys.navItems.byEngagementId(args.engagementId));
            }
        },
        ...mutationErrorHandler(toast, "Unable to enable application"),
    });
}

export function useUpdateApplication() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: updateApplication,
        onSuccess: async (_response: unknown, args: ApplicationArgs & Application) => {
            await queryClient.invalidateQueries(queryKeys.applications.byId(args.engagementId, args.projectId));
            if (args.projectId) {
                await queryClient.invalidateQueries(queryKeys.navItems.byProjectId(args.engagementId, args.projectId));
            } else {
                await queryClient.invalidateQueries(queryKeys.navItems.byEngagementId(args.engagementId));
            }
        },
        ...mutationErrorHandler(toast, "Unable to update application"),
    });
}

export function useUploadDocumentToEngagementDeliverable() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: uploadDocumentToEngagementDeliverable,
        onSuccess: async (response: Document, args: UploadDocumentsToEngagementDeliverableArgs) => {
            await queryClient.invalidateQueries(queryKeys.deliverables.byId(args.engagementId, ""));
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

export function useUploadDocumentToProjectDeliverable() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: uploadDocumentToProjectDeliverable,
        onSuccess: async (response: Document, args: UploadDocumentsToProjectDeliverableArgs) => {
            await queryClient.invalidateQueries(queryKeys.deliverables.byId(args.engagementId, args.projectId || ""));
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

// Engagement Tax Notices
export function useEngagementTaxNotices(engagementId: string | undefined) {
    return useQuery({
        ...queryKeys.engagementTaxNotices.byEngagmentId(engagementId!),
        queryFn: () => {
            return getEngagementTaxNotices({ engagementId: engagementId! });
        },
        enabled: !!engagementId,
    });
}

export function useEngagementTaxNotice(engagementId: string | undefined, taxNoticeId: string | undefined) {
    return useQuery({
        ...queryKeys.engagementTaxNotices.byTaxNoticeId(engagementId!, taxNoticeId!),
        queryFn: () => {
            return getEngagementTaxNotice({ engagementId: engagementId!, taxNoticeId: taxNoticeId! });
        },
        enabled: !!engagementId && !!taxNoticeId,
    });
}

export async function invalidateEngagementTaxNoticeQueries(queryClient: QueryClient, engagementId: string, taxNoticeId: string, isDelete?: boolean) {
    if (isDelete) {
        await queryClient.removeQueries({ queryKey: [...queryKeys.engagementTaxNotices.byEngagmentId._def, engagementId] });
    } else {
        await queryClient.invalidateQueries({ queryKey: [...queryKeys.engagementTaxNotices.byEngagmentId._def, engagementId] });
    }
    await queryClient.invalidateQueries(queryKeys.engagementTaxNotices.byTaxNoticeId(engagementId, taxNoticeId));
    await queryClient.invalidateQueries(queryKeys.engagements.rollup);
}

export function useSaveEngagementTaxNotice() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostEngagementTaxNoticeArgs | PutEngagementTaxNoticeArgs) => {
            if ((args as PutEngagementTaxNoticeArgs).taxNoticeId) {
                return updateEngagementTaxNotice(args as PutEngagementTaxNoticeArgs);
            } else {
                return createEngagementTaxNotice(args);
            }
        },
        onSuccess: async (response: TaxNotice) => {
            invalidateEngagementTaxNoticeQueries(queryClient, response.engagementId || "", response.id || "");
        },
        ...mutationErrorHandler(toast, "Unable to save tax-notice"),
    });
}

export function useDeleteEngagementTaxNotice() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: deleteEngagementTaxNotice,
        onSuccess: async (_response: number, args: DeleteEngagementTaxNoticeArgs) => {
            invalidateEngagementTaxNoticeQueries(queryClient, args.engagementId || "", args.taxNoticeId || "");
        },
        ...mutationErrorHandler(toast, "Unable to delete tax-notice"),
    });
}

export function useUploadDocumentToEngagementTaxNotice() {
    const toast = useToast();
    return useMutation({
        mutationFn: (args: UploadDocumentsToEngagementTaxNoticeArgs) => {
            return uploadDocumentToEngagementTaxNotice(args);
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}

// Project Tax Notices
export function useProjectTaxNotices(engagementId: string | undefined, projectId: string | undefined) {
    return useQuery({
        ...queryKeys.projectTaxNotices.byProjectId(engagementId!, projectId!),
        queryFn: () => {
            return getProjectTaxNotices({ engagementId: engagementId!, projectId: projectId! });
        },
        enabled: !!engagementId && !!projectId,
    });
}

export function useProjectTaxNotice(engagementId: string | undefined, projectId: string | undefined, taxNoticeId: string | undefined) {
    return useQuery({
        ...queryKeys.projectTaxNotices.byTaxNoticeId(engagementId!, projectId!, taxNoticeId!),
        queryFn: () => {
            return getProjectTaxNotice({ engagementId: engagementId!, projectId: projectId!, taxNoticeId: taxNoticeId! });
        },
        enabled: !!engagementId && !!projectId && !!taxNoticeId,
    });
}

export async function invalidateProjectTaxNoticeQueries(
    queryClient: QueryClient,
    engagementId: string,
    projectId: string,
    taxNoticeId: string,
    isDelete?: boolean,
) {
    if (isDelete) {
        await queryClient.removeQueries({ queryKey: [...queryKeys.projectTaxNotices.byProjectId._def, engagementId, projectId] });
    } else {
        await queryClient.invalidateQueries({ queryKey: [...queryKeys.projectTaxNotices.byProjectId._def, engagementId, projectId] });
    }
    await queryClient.invalidateQueries(queryKeys.projectTaxNotices.byTaxNoticeId(engagementId, projectId, taxNoticeId));
}

export function useSaveProjectTaxNotice() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: (args: PostProjectTaxNoticeArgs | PutProjectTaxNoticeArgs) => {
            if ((args as PutProjectTaxNoticeArgs).taxNoticeId) {
                return updateProjectTaxNotice(args as PutProjectTaxNoticeArgs);
            } else {
                return createProjectTaxNotice(args);
            }
        },
        onSuccess: async (response: TaxNotice) => {
            invalidateProjectTaxNoticeQueries(queryClient, response.engagementId || "", response.projectId || "", response.id || "");
        },
        ...mutationErrorHandler(toast, "Unable to save tax-notice"),
    });
}

export function useDeleteProjectTaxNotice() {
    const queryClient = useQueryClient();
    const toast = useToast();
    return useMutation({
        mutationFn: deleteProjectTaxNotice,
        onSuccess: async (_response: number, args: DeleteProjectTaxNoticeArgs) => {
            invalidateProjectTaxNoticeQueries(queryClient, args.engagementId || "", args.projectId || "", args.taxNoticeId || "", true);
        },
        ...mutationErrorHandler(toast, "Unable to delete tax-notice"),
    });
}

export function useUploadDocumentToProjectTaxNotice() {
    const toast = useToast();
    return useMutation({
        mutationFn: (args: UploadDocumentsToProjectTaxNoticeArgs) => {
            return uploadDocumentToProjectTaxNotice(args);
        },
        ...mutationErrorHandler(toast, "Unable to upload file"),
    });
}
